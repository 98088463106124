import { createRouter, createWebHistory } from "vue-router";
import { Modal } from "ant-design-vue";
import { store } from "../store";
import { registerStateMap } from "../utils/const";
import { RegisterStatus } from "../../types";

const routes = [
  {
    path: "/",
    name: "Visits",
    component: () =>
      import(/* webpackChunkName: "visits" */ "../views/Visits.vue"),
  },
  {
    path: "/history",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/History.vue"),
  },
  {
    path: "/visit/:id",
    name: "VisitDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "visit-details" */ "../views/VisitDetails.vue"
      ),
  },
  {
    path: "/:id",
    redirect: (to: any) => {
      return `/visit/${to.params.id}`;
    },
  },
  {
    path: "/availability",
    name: "Availability",
    component: () =>
      import(
        /* webpackChunkName: "availability" */ "../views/Availability.vue"
      ),
  },
  {
    path: "/billing",
    name: "Billing",
    component: () =>
      import(/* webpackChunkName: "billing" */ "../views/Billing.vue"),
  },
  {
    path: "/certification",
    name: "Certification",
    component: () =>
      import(
        /* webpackChunkName: "certification" */ "../views/Certification.vue"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },
  {
    path: "/register/personal",
    name: "RegisterPersonal",
    meta: { register: true },
    component: () =>
      import(
        /* webpackChunkName: "register-personal" */ "../views/RegisterPersonal.vue"
      ),
  },
  {
    path: "/register/specializations",
    name: "RegisterSpecializations",
    meta: { register: true },
    component: () =>
      import(
        /* webpackChunkName: "register-specializations" */ "../views/RegisterSpecializations.vue"
      ),
  },
  {
    path: "/register/contract",
    name: "RegisterContract",
    meta: { register: true },
    component: () =>
      import(
        /* webpackChunkName: "register-contract" */ "../views/RegisterContract.vue"
      ),
  },
  {
    path: "/register/waiting-for-accept",
    name: "RegisterWaitingForAccept",
    meta: { register: true },
    component: () =>
      import(
        /* webpackChunkName: "register-waiting-for-accept" */ "../views/RegisterWaitingForAccept.vue"
      ),
  },
  {
    path: "/conversations",
    name: "Conversations",
    component: () =>
      import(
        /* webpackChunkName: "Conversations" */ "../views/Conversations.vue"
      ),
  },
  {
    path: "/conversation/:id",
    name: "Conversation",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Conversation" */ "../views/Conversation.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => {
  Modal.destroyAll();

  if (store.state.user.id) {
    if (!to.name) {
      next("/");
    } else {
      const registerStatus = store.state.user.registerStatus;

      switch (registerStatus) {
        case "FULLY_REGISTERED": {
          if (to.matched.some((record) => record.meta.register)) {
            next({
              path: "/",
            });
          } else {
            next();
          }
          break;
        }
        case "WAITING_FOR_ACCEPT": {
          if (to.path === "/register/waiting-for-accept") {
            next();
          } else {
            next("/register/waiting-for-accept");
          }
          break;
        }
        default: {
          if (!to.matched.some((record) => record.meta.register)) {
            next({
              path: `/register/${registerStatus
                .toLowerCase()
                .replaceAll("_", "-")}`,
            });
          } else {
            const targetRegisterIndex = registerStateMap.findIndex(
              (value: RegisterStatus) =>
                value ===
                to.path.split("/")[2].toUpperCase().replaceAll("-", "_")
            );
            const currentRegisterIndex = registerStateMap.findIndex(
              (value: RegisterStatus) => value === registerStatus
            );
            if (targetRegisterIndex > currentRegisterIndex) {
              next({
                path: `/register/${registerStatus
                  .toLowerCase()
                  .replaceAll("_", "-")}`,
              });
            } else {
              next();
            }
          }
        }
      }
    }
  }
});

export default router;
